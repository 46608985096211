import React from "react";
import MainSection from "../components/GaleriaComponents/MainSection";
import { Helmet } from "react-helmet";

export default function Galeria() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Galeria | Stragona</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <MainSection></MainSection>
    </>
  );
}
