import React from "react";
import styled from "styled-components";
import PokojeIMG from "../../assets/pokoje.jpg";
import { SRLWrapper } from "simple-react-lightbox";
import { StaticImage } from "gatsby-plugin-image";
import Photo1 from "../../assets/wynajmij/1.jpg";
import Photo2 from "../../assets/wynajmij/2.jpg";
import Photo3 from "../../assets/wynajmij/3.jpg";
import Fade from "react-reveal/Fade";
const MainSection = () => {
  return (
    <Wrapper>
      <Header>
        <h1 className="text">Galeria</h1>
      </Header>

      <div className="container">
        <SRLWrapper>
          <StaticImage
            src="../../assets/wynajmij/1.jpg"
            alt="Galeria Stragona"
          />
          <StaticImage
            src="../../assets/wynajmij/3.jpg"
            alt="Galeria Stragona"
          />
          <StaticImage
            src="../../assets/wynajmij/5.jpg"
            alt="Galeria Stragona"
          />
          <StaticImage
            src="../../assets/wynajmij/7.jpg"
            alt="Galeria Stragona"
          />
          <StaticImage
            src="../../assets/wynajmij/9.jpg"
            alt="Galeria Stragona"
          />
          <StaticImage
            src="../../assets/wynajmij/10.jpg"
            alt="Galeria Stragona"
          />
          <StaticImage
            src="../../assets/wynajmij/12.jpg"
            alt="Galeria Stragona"
          />
          <StaticImage
            src="../../assets/wynajmij/14.jpg"
            alt="Galeria Stragona"
          />
          <StaticImage
            src="../../assets/wynajmij/16.jpg"
            alt="Galeria Stragona"
          />
          <StaticImage
            src="../../assets/wynajmij/17.jpg"
            alt="Galeria Stragona"
          />
          <StaticImage
            src="../../assets/wynajmij/18.jpg"
            alt="Galeria Stragona"
          />
          <StaticImage
            src="../../assets/wynajmij/20.jpg"
            alt="Galeria Stragona"
          />
          <StaticImage
            src="../../assets/wynajmij/21.jpg"
            alt="Galeria Stragona"
          />
          <StaticImage
            src="../../assets/wynajmij/22.jpg"
            alt="Galeria Stragona"
          />
        </SRLWrapper>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .text_container {
    @media (max-width: 1024px) {
      padding: 80px 54px;
    }
    padding: 80px 288px;
    h2 {
      font-size: 40px;
      font-family: "Josefin Slab", serif;
      color: #495453;
      text-align: center;
    }
    p {
      font-size: 16px;
      text-align: center;
      color: #495453;
      line-height: 1.75;
      font-weight: 200;
      margin: 40px 180px;
      @media (max-width: 1024px) {
        margin: 40px 10px;
      }
    }
  }
  .container {
    padding-bottom: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }
    img {
      width: 400px;
      height: 400px;
      margin: 10px;
      cursor: zoom-in;
      @media (max-width: 640px) {
        width: 320px;
        height: 320x;
      }
    }
  }
`;

const Header = styled.div`
  height: 55vh;
  background-image: url(${PokojeIMG});
  background-position: center;
  position: relative;
  background-size: cover;

  .text {
    width: 440px;
    position: absolute;
    bottom: 5vh;
    text-align: center;
    font-family: "Josefin Slab", serif;
    font-weight: 200;
    font-size: 80px;
    left: calc(50% - 220px);
    color: white;
    @media (max-width: 1024px) {
      font-size: 46px;
      width: 320px;
      left: calc(50% - 160px);
    }
  }
`;

export default MainSection;
